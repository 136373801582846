<template>
    <div>
        <div>
            <VoucherOptions></VoucherOptions>
        </div>
    </div>
</template>

<script>
// import transactionPin from "../services/transactionPin";
import VoucherOptions from "../Voucher/VoucherOptions.vue";

export default {
   name: "OptionsType",
   components: {
      VoucherOptions,
    // Loader,
  },
   data() {
      return {
         loading: false,
      }
   },
   methods: {
    // optionss () {
    //     this.$router.push({path: `voucher`});
    // }
   },
}
</script>