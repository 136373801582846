<template>
    <div>
        <div class="folder contianer-fluid">
            <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>

            <div class="">
                <div class="row">
                  <div class="col-md-8">
                    <div class="voucher-name" v-if="type == 'standard'">
                        <h3>Standard buy</h3>
                        <h6>You can generate upto 5 voucher per day</h6>
                    </div>
                    <div class="voucher-name" v-if="type == 'bulk'">
                        <h3>Bulk buy</h3>
                        <h6>You can generate from 10 and above vouchers</h6>
                    </div>

                    <div class="m-auto text-center mt-4" v-if="lloading"><Loader/></div>

                    <div v-else class="voucher-options">
                        <h6 v-if="type == 'standard'"><p></p>Your limit today: {{ voucherCount }}</h6>
                        <div class="">
                            <!-- <div v-if="type == 'standard'"> -->
                                <label for="">What is the voucher for? <span>*</span></label>

                                <div class="form-check" v-for="(item, index) in voucherTpes" :key="index">
                                    <input class="form-check-input" type="radio" name="exampleRadios" @change="voucherTypeChange(item.amount, item.name);" v-model="voucher_type_id" :id="item.id" :value="item.id">
                                    <label class="form-check-label" :for="item.name">
                                        {{item.name}}
                                    </label>
                                </div>

                                <!-- <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">
                                    <label class="form-check-label" for="exampleRadios1">
                                        Smart subscription
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                    <label class="form-check-label" for="exampleRadios2">
                                        Smart plan subscription/renewal
                                    </label>
                                </div> -->
                            <!-- </div> -->



                            <!-- <div v-if="type == 'bulk'" class="mt-5">
                                <label for="">For which plan? <span>*</span></label>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="plan" id="exclusive" value="exclusive" >
                                    <label class="form-check-label" for="exclusive">
                                        Exclusive
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="plan" id="premium" value="premium">
                                    <label class="form-check-label" for="premium">
                                        premium
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="plan" id="alliance" value="alliance">
                                    <label class="form-check-label" for="alliance">
                                        Alliance
                                    </label>
                                </div>
                            </div> -->

                            <div class="quantity-holder">
                                <label for="">Select quantity <span>*</span></label>
                                <div class="quantities">
                                    <div class="quantity">
                                        <div  v-for="(item, index) in voucherTypesArray.slice(0, 3)" :key="index">
                                            <h4 :class="[activeIndex == item.id ? 'quantityActive' : '']" @click="activeIndex = item.id;">{{item.name}}</h4>
                                        </div>
                                    </div>

                                    <div class="quantity">
                                        <div  v-for="(item, index) in voucherTypesArray.slice(-2)" :key="index">
                                            <h4 :class="[activeIndex == item.id ? 'quantityActive' : '']" @click="activeIndex = item.id;">{{item.name}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mt-4" v-if="type == 'bulk'">
                                    <input v-model="activeIndex" name="numberr" id="numberr" type="number" class="form-control" placeholder="10 - above">
                                </div>
                            </div>
                            <hr>

                            <div v-if="type == 'bulk'">
                                <label for="paywith">Paying with</label>
                                <div class="smartWalletCard mb-3" :class="paymentMethod == 'smart_wallet' ? 'activePayment' : '' " @click="selectPayment = true; paymentMethod = 'smart_wallet'">
                                    <img src="../assets/icons/wallet-2.svg" alt="wallet">
                                    <div class="card-text">
                                        <h3>Smart wallet</h3>
                                        <h4>Pay directly using your Smart wallet</h4>
                                    </div>
                                </div>

                                <div class="smartWalletCard" :class=" paymentMethod == 'transfer' ? 'activePayment' : '' " @click="selectPayment = true; paymentMethod = 'transfer'" data-toggle="modal" data-target="#transferOption">
                                    <img src="../assets/icons/wallet-2.svg" alt="wallet">
                                    <div class="card-text">
                                        <h3>Transfer</h3>
                                        <h4>Transfer the payment to Shelta's bank account </h4>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block d-md-none mt-5">
                                <h5 class="top-right-text1">You will pay</h5>
                                <h2 class="top-right-text2">₦{{ Number(amountToPay).toLocaleString() }}</h2>

                                <div class="breakdown">
                                    <div class="d-flex justify-content-between">
                                        <p>Voucher purpose</p>
                                        <p>{{voucherName}}</p>
                                    </div>
                                    
                                    <div class="d-flex justify-content-between">
                                        <p>Quantity</p>
                                        <p>{{ activeIndex }} voucher(s)</p>
                                    </div>
                                    <hr>

                                    <div class="d-flex justify-content-between">
                                        <p>Qty x price</p>
                                        <p>{{ activeIndex }} x ₦{{Number(voucherTypeAmount).toLocaleString()}}</p>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <p>Our fees</p>
                                        <p>₦0</p>
                                    </div>

                                    <hr>

                                    <div class="d-flex justify-content-between" v-if="amountToPay">
                                        <h4>You pay</h4>
                                        <h5>₦{{  Number(amountToPay).toLocaleString() }}</h5>
                                    </div>
                                </div>
                            </div>


                            <p class="confirm-text">Note: confrim before submitting</p>
                            <div class="form-group">
                                <button :disabled="loading || (this.paymentMethod == 'transfer' && this.receiptServerFileName == '')" class="btn submit" @click="generateVoucher()">
                                    {{ loading ? 'Generating...' : 'Submit' }}
                                </button>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div class="col-md-4 d-none d-md-block">
                    <div>
                        <h5 class="top-right-text1">You will pay</h5>
                        <h2 class="top-right-text2">₦{{ Number(amountToPay).toLocaleString() }}</h2>

                        <div class="breakdown">
                            <div class="d-flex justify-content-between">
                                <p>Voucher purpose</p>
                                <p>{{voucherName}}</p>
                            </div>
                            
                            <div class="d-flex justify-content-between">
                                <p>Quantity</p>
                                <p>{{ activeIndex }} voucher(s)</p>
                            </div>
                            <hr>

                            <div class="d-flex justify-content-between">
                                <p>Qty x price</p>
                                <p>{{ activeIndex }} x {{voucherTypeAmount}}</p>
                            </div>

                            <div class="d-flex justify-content-between">
                                <p>Our fees</p>
                                <p>₦0</p>
                            </div>

                            <hr>

                            <div class="d-flex justify-content-between" v-if="amountToPay">
                                <h6>You pay</h6>
                                <h5>₦{{ Number(amountToPay).toLocaleString() }}</h5>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" tabindex="-1" id="transferOption">
                    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Payment confirmation</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="transfer-content modal-body">
                                <h4>1. Upload payment receipt</h4>
                                <p>Please upload a clear picture or screenshot of your payment receipt to help us verify your transaction quickly.</p>
                                <p>Account name: <span>Shelta Panacea Limited</span></p>
                                <p>Account number: <span>2046257231</span></p>
                                <p>Bank: <span>First Bank.</span></p>

                                <div class="upload-section">
                                    <div class="text-side">
                                        <img src="../assets/icons/upload.svg" alt="upload">
                                        <div>
                                            <h6 v-if="receiptFile == ''">Upload proof of payment</h6> 
                                            <h6 v-else>{{receiptFile.name}}</h6>
                                            <h6>PDF, JPG, PNG, DOC (max. 5MB)</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <button @click="uploadTriggered()" class="btn">Browse</button>
                                        <!-- <input type="file" ref="receipt" class="d-none"> -->
                                        <input type="file" ref="receipt" @change="handleFileChnage($event)" class="d-none">
                                    </div>
                                </div>

                                <h4 class="mt-4">2. Acknowledge and Confirm</h4>
                                <p class=""><img class="mr-1" src="../assets/icons/tick-circle.svg" alt="check">By clicking 'upload,' you confirm that the payment has been made to the correct account with the payment or transfer receipt provided by uploading it.</p>
                                <p class=""><img class="mr-1" src="../assets/icons/alert-triangle.svg" alt="check"> Providing false information may result in penalties, including account suspension or legal action.</p>

                                <div class="form-check mb-5">
                                    <input class="form-check-input" true-value="YES" false-value="NO" type="checkbox" value="" id="defaultCheck1" v-model="termsConditions">
                                    <label class="form-check-label" for="defaultCheck1">
                                        I have reviewed and agree to the guidelines stated above.
                                    </label>
                                </div>

                                <div class="form-group upload-btn">
                                    <button @click="uploadReceipt()" :disabled="uploadingReceipt || receiptFile == '' || termsConditions == 'NO'" class="btn">
                                        {{ uploadingReceipt ? 'Uploading...' : 'Upload' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import generatingVoucher from "../services/voucher";
import Loader from "../components/Loader.vue";
import $ from "jquery";
import trip from "../services/trip";

export default {
   name: "VoucherOptions",
   components: {
    Loader,
  },
   data() {
      return {
         loading: false,
         lloading: false,
         activeIndex: "",
         selectPayment: false,
         uploadingReceipt: false,
         receiptFile: "",
         receiptFileName: "",
         receiptServerFileName: "",
         termsConditions: "NO",
         type: "",
         voucherCount: null,
         voucherName: "",
         voucher_type_id: "",
         quantityNumber: "",
         paymentMethod: "",
         voucherTypeAmount: 0,
         voucherTpes: [],
         voucherTypesArray: [],
         amountToPay: null,
         voucherTypesStandard: [
            {
                name: '1 Voucher',
                id: 1
            },
            {
                name: '2 Voucher',
                id: 2
            },
            {
                name: '3 Voucher',
                id: 3
            },
            {
                name: '4 Voucher',
                id: 4
            },
            {
                name: '5 Voucher',
                id: 5
            },
         ],


         voucherTypesBulk: [
            {
                name: '10 Voucher',
                id: 10
            },
            {
                name: '30 Voucher',
                id: 30
            },
            {
                name: '50 Voucher',
                id: 50
            },
            {
                name: '70 Voucher',
                id: 70
            },
            {
                name: '100 Voucher',
                id: 100
            },
         ]
      }
   },
   watch: {
        voucher_type_id: "checkValues",
        activeIndex: "checkValues"
   },
   mounted() {
    this.type = this.$route.params.type;
    if ( this.type == 'standard') {
       this.voucherTypesArray = this.voucherTypesStandard;
    } else {
        this.voucherTypesArray = this.voucherTypesBulk
    }
    this.fetchVoucherTypes();
    this.voucherUseCount();
   },
   methods: {
    checkValues() {
      if (this.voucher_type_id && this.activeIndex && this.type == 'bulk') {
        this.discountedVoucher();
      }
      if (this.voucher_type_id && this.activeIndex && this.type == 'standard') {
       this.amountToPay = (this.activeIndex * this.voucherTypeAmount);
      }
    },
    callFunction() {
      console.log("Both values are set! Calling the function...");
    },
    backBtn() {
        this.$router.push({path: `/user/voucher`});
    },
    uploadTriggered() {
        this.$refs.receipt.click();
    },  

    handleFileChnage(event) {
        this.receiptFile = event.target.files[0];
        this.receiptFileFileName = this.receiptFile.name;
    },
    discountedVoucher() {
        const body = {
            voucher_type_id: this.voucher_type_id,
            quantity: this.activeIndex, 
        }
        generatingVoucher.voucverDiscount(JSON.stringify(body)).then(res => {
            console.log("generated discount", res);
            if (res.success) {
                this.amountToPay = res.amount;
                console.log("apay amount", this.amountToPay);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    uploadReceipt () {
        this.uploadingReceipt = true;
        if (this.voucher_type_id == '') {
            this.uploadingReceipt = false;
            return this.$toast.error("Select voucher type");
        }
        if (this.activeIndex == '') {
            this.uploadingReceipt = false;
            return this.$toast.error("Select voucher quantity");
        }
        
        if (!this.receiptFile) {
            this.uploadingReceipt = false;
            // this.$toast.success("success");
            return this.$toast.error("select a file");
        }
        const sizeInMb = this.receiptFile.size / (1024 * 1024).toFixed(3);
        if (sizeInMb > 5) {
            this.uploadingReceipt = false;
            return this.$toast.error("File size more than 5MB");
        }
        let formData = new FormData();
        formData.append("file", this.receiptFile);
        formData.append("documentType", this.receiptFile.name);
        generatingVoucher.uploadTransferReceipt(formData)
        .then((res) => {
            this.uploadingReceipt = false;
            if (res.success) {
                this.receiptServerFileName = res.filename
                $("#transferOption")
                  .removeClass("fade")
                  .modal("hide");
            this.$toast.success("RECEIPT UPLOADED SUCCESSFULY");
            } else {
            // this.$toast.error("ERROR");
            this.$toast.error(res.error);
            }
        })
        .catch((err) => {
            console.log(err);
            this.uploadingReceipt = false;
        });
    },
    voucherTypeChange(amount, name) {
        this.voucherTypeAmount = amount;
        this.voucherName = name;
    },  
    fetchVoucherTypes() {
        this.lloading = true;
        generatingVoucher.voucherTypes().then((res) => {
            this.lloading = false;
            this.voucherTpes = res.voucher_types;
        }).catch((err) => {
            console.log(err);
            this.lloading = false;
        });
    },
    voucherUseCount() {
        trip.voucherDailyCount().then((res) => {
            this.voucherCount = res;
        }).catch((err) => {
            console.log(err);
        });
    },
    async generateVoucher() {
        this.loading = true;
        if (this.voucher_type_id == '') {
            this.loading = false;
            return this.$toast.error("Select voucher type");
        }
        if (this.activeIndex == '') {
            this.loading = false;
            return this.$toast.error("Select voucher quantity");
        }
        if (this.type == 'bulk' && this.paymentMethod == '') {
            this.loading = false;
            return this.$toast.error("Select a payment method");
        }
        if (this.receiptServerFileName == '' && this.paymentMethod == 'transfer') {
            this.loading = false;
            return this.$toast.error("No receipt file was uploaded");
        }
        if (this.type == 'bulk' && this.activeIndex < 10) {
            this.loading = false;
            return this.$toast.error("Voucher quantity must be more that 10");
        }
        let url = null;
        url = this.type == 'standard' ? url = 'generate-voucher' : 'voucher-bulk-buy'
        const body = {
            voucher_type_id: this.voucher_type_id,
            quantity: this.activeIndex,
            payment_method: this.paymentMethod,
            receipt: this.receiptServerFileName,
        }
        try {
            await generatingVoucher.generateVoucher(JSON.stringify(body), url).then((res) => {
                this.loading = false;
                if (res.success) {
                    this.$toast.success("SUCCESS");
                    this.$router.push({path: `/user/dashboard`});
                }
                if (url == 'standard') {
                    this.voucherUseCount();
                }
                if (res.error) {
                    this.$toast.error(res.error);
                }
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        } finally {
            // console.log("closed");
        }
    }
   },
}
</script>


<style scoped lang="scss">

.folder  {
    background: #ffffff;
    padding: 30px 15px;
}

.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}

.voucher-name {
    margin-bottom: 20px;
    h3 {
        font-family: Lato;
        font-weight: 400;
        font-size: 36px;
        // line-height: 44px;
        color: #2B3352;

    }
    h6 {
        font-family: Lato;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #2B3352;
    }
}

.voucher-options {
    border: 1px solid #E9EAEB;
    border-radius: 20px;
    padding: 24px 10px;
    h6 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #555C74;
        display: block;
        margin-left: auto !important;
        // justify-content: right;
        background: #EFF8FF;
        padding: 5px 10px 5px 18px;
        width: fit-content;
        border-radius: 10px;
        display: flex;
        position: relative;
        margin-bottom: 35px;
        p {
            width: 6px;
            height: 6px;

            background: #2E90FA;
            border-radius: 50px;
            position: absolute;
            left: 8px;
            bottom: 11px;
        }
    }
}

.form-check {
    margin-bottom: 15px;
}

.form-check-input {
    width: 18px;
    height: 18px;
}

label {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #555C74;
    width: fit-content;
    // letter-spacing: 0%;

}

.form-check-label {
    padding-top: 4px;
    padding-left: 8px;
}
.quantity-holder {
    margin-top: 35px;
}
.quantity {
    display: flex;
    gap: 10px;
    h4 {
        border: 1px solid #E9EAEB;
        font-family: Lato;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #555C74;
        text-align: center;
        border-radius: 10px;
        width: fit-content;
        padding: 5px 10px;
        cursor: pointer;
    }
}

.quantityActive {
    border: 1px solid #0033EA !important;
    color: #0033EA !important;
}

.smartWalletCard {
    border: 1px solid #D5D7DA;
    border-radius: 12px;
    padding: 16px;
    background: #FAFAFA;
    display: flex;
    gap: 15px;
    cursor: pointer;
    h3 {
        font-family: Lato;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2B3352;
        margin-bottom: 0;
    }
    h4 {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #555C74;
        margin-bottom: 0;
    }
}

.activePayment {
    border: 1px solid #0033EA !important;
    h3 {
        font-size: 17px !important;
    }
    h4 {
        font-size: 15px !important;
    }
}

.submit {
    margin-top: 40px;
    // margin-bottom: 40px;
    background: #0033EA;
    color: #ffffff;
    font-family: Lato;
    font-weight: 600;
    font-size: 18px;
    box-shadow: none;
    outline: 0;
    height: 60px;
    width: 100%;
}

.top-right-text1 {
    font-family: Lato;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #2B3352;
}
.top-right-text2 {
    font-family: Lato;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #2B3352;
    margin-bottom: 30px;

}

.breakdown {
    background: #FAFAFA;
    border-radius: 20px;
    padding: 24px;
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #555C74;

    }
    h5, h6, h4 {
        font-family: Lato;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

    }
    h5 {
        color: #2B3352;
    }
    h6 {
        color: #555C74;
    }
    div {
        margin-bottom: 20px;
    }
}
.form-group {
    input {
        border: 1px solid #D5D7DA;
        height: 44px;
        box-shadow: none;
        outline: 0;
        color: #2B3352;
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;

    }
}

.confirm-text {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e1231d;
    margin-top: 20px !important;
}

.transfer-content {
    padding: 20px 24px !important;
    h4 {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #2B3352;
        text-align: left;
    }
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #555C74;
        text-align: left;
        margin-bottom: 15px !important;
        span {
            font-weight: bold;
        }
    }
}
.upload-section {
    border: 1px dashed #E4E7EC;
    border-radius: 6px;
    padding: 12px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    button {
        outline: 0;
        box-shadow: none;
        border: 1px solid #D5D7DA;
        color: #414651;
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        background: white;
        // padding: 8px 12px;
        border-radius: 8px;
        height: 36px;
    }
}

.text-side {
    display: flex;
    gap: 10px;
    h6 {
        font-family: Lato;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #555C74
    }
}
.upload-btn {
    button {
        background: #0033EA;
        color: #ffffff;
        font-family: Lato;
        font-weight: 600;
        font-size: 16px;
        height: 48px;
        width: 100%;
    }
}

@media screen and (max-width: 599px) {
    .upload-section {
        display: block;
        justify-content: center;
        button {
            margin-top: 20px;
            width: 100%;
        }
    }
}
</style>